#toggle {
  display: none;
}
.icon-container {
  display: grid;
  place-items: start;
  margin-right: 10px;
}
.hamburger {
  right: 0;
  align-self: center;
  width: 2em;
  z-index: 5;
}

.hamburger div {
  position: relative;
  width: 3em;
  height: 0.4em;
  /* border-radius: 3px; */
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
  background-color: #d10000ee;
}

#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  /* margin-top: 0.4em; */
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -7px;
}

#toggle:checked + .hamburger {
  top: 0;
  transform: scale(1);
}
