*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  #Logo:hover {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 5rem;
  color: white;
  text-shadow: 3px 0px rgb(200, 0, 0);
  text-transform: uppercase;
  text-align: center;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#navbar {
  /*background-color: rgb(37, 41, 46);*/
  background-color: #011627;
  /*background: linear-gradient(0deg, rgba(160,0,0,1) 0%, rgba(180,0,0,1) 48%, rgba(160,0,0,1) 100%);*/
}
#navbar button {
  font-size: 20px;
  color: #fbfbff;
}

#navbar button:hover {
  font-weight: bold;
}

#navbar button .active {
  font-weight: bold;
  color: #fcd0a1;
}

body {
  /*background-color: #2e3137;*/
  background-color: #011627;
  color: white;
}

p {
  text-align: wrap;
}
/* @media screen and (max-width: 1080px) {
  .menu-div svg {
    display: none;
  }
} */

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}
